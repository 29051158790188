body.public {
  font-family: 'Noto Sans', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: 'wdth' 100;
}

.public .monospace {
  font-family: 'Noto Sans Mono', monospace;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: 'wdth' 100;
  color: #006;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 1.5em;
}
