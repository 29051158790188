/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.messenger .message-error > .messenger-message-inner {
  /* font: normal normal normal 14px/1 FontAwesome; */
}

ul.messenger-theme-flat .message-error .messenger-message-inner:before {
  font: normal normal normal 14px/1 FontAwesome;
  content: '\f071';
  color: #dd6a45;
  -webkit-border-radius: 0;
  border-radius: 0;
}

ul.messenger-theme-flat .messenger-message.alert-error .messenger-message-inner:before {
  background: none;
}
