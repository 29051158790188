/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.sales-finance-affirmation-section-view #salesFinanceAffirmationCheckBoxLabel {
  display: block;
  padding-left: 15px;
  text-indent: -15px;
}

.sales-finance-affirmation-section-view #salesFinanceAffirmationCheckBox {
  width: 13px;
  height: 13px;
  padding: 0;
  margin: 0;
  vertical-align: middle;
  position: relative;
  top: -1px;
}
