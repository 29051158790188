.calc #result {
  font-family: 'Noto Sans Mono', monospace;
  font-size: 1.5em;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: 'wdth' 100;
  color: #060;
  margin: 0.5em 0;
}

.calc label {
  text-align: right;
}

.calc input,
.input-group-addon {
  font-family: 'Noto Sans Mono', monospace;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: 'wdth' 100;
}

.calc h1 {
  color: #030;
  font-family: 'Noto Sans', sans-serif;
  font-weight: bold;
  font-variant: small-caps;
  font-size: 1.5em;
}

