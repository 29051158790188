.conser-complaint-form-issue-view .checkbox-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 30px;
}

.conser-complaint-form-issue-view .checkbox {
  margin-right: 20px;
  margin-bottom: 10px;
}

.conser-complaint-form-issue-view .checkbox label {
  display: block;
  margin-left: -20px;
  margin-right: 20px;
}

.conser-complaint-form-issue-view .form-horizontal .control-label {
  padding-left: 17px;
  text-align: left;
}

.conser-complaint-form-issue-view textArea {
  margin-top: 20px;
}