/* MARGINS & PADDINGS */
.p-xs {
  padding: 5px !important;
}
.p-sm {
  padding: 10px !important;
}
.p-md {
  padding: 15px !important;
}
.p-lg {
  padding: 20px !important;
}
.p-n {
  padding: 0 !important;
}

.p-l-n {
  padding-left: 0 !important;
}

.p-t-n {
  padding-top: 0 !important;
}

.p-r-n {
  padding-right: 0 !important;
}

.p-b-n {
  padding-bottom: 0 !important;
}

.m-xs {
  margin: 5px !important;
}
.m-sm {
  margin: 10px !important;
}
.m-md {
  margin: 15px !important;
}
.m-lg {
  margin: 20px !important;
}
.m-n {
  margin: 0px !important;
}

.m-l-xs {
  margin-left: 5px !important;
}
.m-l-sm {
  margin-left: 10px !important;
}
.m-l-md {
  margin-left: 15px !important;
}
.m-l-lg {
  margin-left: 20px !important;
}
.m-l-n {
  margin-left: 0 !important;
}

.m-t-xs {
  margin-top: 5px !important;
}
.m-t-sm {
  margin-top: 10px !important;
}
.m-t-md {
  margin-top: 15px !important;
}
.m-t-lg {
  margin-top: 20px !important;
}
.m-t-n {
  margin-top: 0 !important;
}

.-m-t-xs {
  margin-top: -5px !important;
}
.-m-t-sm {
  margin-top: -10px !important;
}
.-m-t-md {
  margin-top: -15px !important;
}
.-m-t-lg {
  margin-top: -20px !important;
}

.m-r-xs {
  margin-right: 5px !important;
}
.m-r-sm {
  margin-right: 10px !important;
}
.m-r-md {
  margin-right: 15px !important;
}
.m-r-lg {
  margin-right: 20px !important;
}
.m-r-n {
  margin-right: 0 !important;
}

.m-b-xs {
  margin-bottom: 5px !important;
}
.m-b-sm {
  margin-bottom: 10px !important;
}
.m-b-md {
  margin-bottom: 15px !important;
}
.m-b-lg {
  margin-bottom: 20px !important;
}
.m-b-n {
  margin-bottom: 0 !important;
}

.space-15 {
  margin: 15px 0 !important;
}
.space-20 {
  margin: 20px 0 !important;
}
.space-25 {
  margin: 25px 0 !important;
}
.space-30 {
  margin: 30px 0 !important;
}

.bottom-spacer {
  margin-bottom: 150px;
}
