/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.non-dep-internal-individual-search-view .backgrid.table .non-dep-individual-row-view .individuals-cell {
  padding: 0;
}

.non-dep-individual-row-view .table > tbody > tr > td {
  padding: 4px 5px;
}

.non-dep-individual-row-view .individuals-cell > table {
  width: 100%;
  margin: 0;
  border: 0;
}

.non-dep-individual-row-view .individuals-cell-row:first-child > td {
  border-top: 0;
}

.non-dep-individual-row-view .individuals-cell-row:last-child > td {
  border-bottom: 0;
}

.non-dep-individual-row-view .individuals-cell .name {
  width: 22.2%;
}

.non-dep-individual-row-view .individuals-cell .entity-name {
  width: 24.4%;
}

.non-dep-individual-row-view .individuals-cell .address {
  width: 24.4%;
}

.non-dep-individual-row-view .individuals-cell .matches {
  width: 23.3%;
}

.non-dep-individual-row-view .individuals-cell .open-button {
  width: 5.5%;
}
