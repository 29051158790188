/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.side-nav-link-collection-view.nav {
  background-color: #fff;
  border: 1px solid #d1d5da;
  border-radius: 4px;
}

.side-nav-link-collection-view.nav-pills > li + li {
  margin-left: 0;
}

.side-nav-link-collection-view.nav > li > a {
  border-radius: 0;
  border-bottom: 1px solid #e1e4e8;
  padding: 10px;
  font-size: 14px;
  min-height: 40px;
  line-height: 23px;
}

.side-nav-link-collection-view.nav > li:first-child > a {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top: 0;
}

.side-nav-link-collection-view.nav > li:last-child > a {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom: 0;
}

.side-nav-link-collection-view.nav > li > a.has-errors {
  background-color: #e66d48;
  color: #fff;
}

.side-nav-link-collection-view.nav > li.active > a.has-errors {
  background-color: #2a578d;
}

.side-nav-link-collection-view.nav > li > a.has-errors:hover,
.side-nav-link-collection-view.nav > li > a.has-errors:focus {
  background-color: #ea5022;
}

.side-nav-link-collection-view.nav > li.active > a.has-errors:hover,
.side-nav-link-collection-view.nav > li.active > a.has-errors:focus {
  background-color: #1e3f66;
}
