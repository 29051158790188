/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.con-ser-ext-dashboard-view {
  margin-bottom: 20px;
}

.con-ser-ext-dashboard-view .dashboard-container h1 {
  margin-top: 0;
  margin-bottom: 20px;
}

.con-ser-ext-dashboard-view .dashboard-container h2 {
  margin-top: 0px;
  font-size: 28px;
}

.con-ser-ext-dashboard-view .actions-container h2 {
  margin-top: 0;
}

.con-ser-ext-dashboard-view .self-service-page-title {
  padding: 15px 0 15px 15px;
  margin: -20px -15px 10px -15px;
  font-weight: 300;
}

.con-ser-ext-dashboard-view .self-service-page-title > *,
.page-subtitle > * {
  display: inline-block;
}

.con-ser-ext-dashboard-view .self-service-page-title-buttons-container {
  float: right;
  padding-right: 15px;
}

.con-ser-ext-dashboard-view .self-service-page-title-buttons-container > * {
  display: inline-block;
}

.dashboard-container .btn-file-new-complaint-container {
  float: right;
  padding-right: 15px;
}
