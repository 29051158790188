/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.con-ser-complaint-search-view {
  display: flex;
  align-items: stretch;
  min-height: 100%;
}

.con-ser-complaint-search-view > .side-bar-container {
  width: 200px;
  min-width: 200px;
}

@media screen and (min-width: 1300px) {
  .con-ser-complaint-search-view > .side-bar-container {
    width: 300px;
    min-width: 300px;
  }
}

.con-ser-complaint-search-view > .dashboard-container {
  padding-left: 15px;
}

.con-ser-complaint-search-view > .dashboard-container h2 {
  margin-top: 0px;
}

.con-ser-complaint-search-view .user-tasks-container {
  margin-bottom: 40px;
}

.con-ser-complaint-search-view .backgrid .link-button-cell {
  text-align: center !important;
}

.con-ser-complaint-search-view .backgrid.table > thead > tr > td,
.con-ser-complaint-search-view .backgrid.table > tbody > tr > td {
  padding: 4px 5px;
}

.con-ser-complaint-search-view .backgrid.table > thead > tr,
.con-ser-complaint-search-view .backgrid.table > tbody > tr {
  height: 37px;
}

.con-ser-complaint-search-view .backgrid.table tbody tr {
  font-size: 12px;
}

.con-ser-complaint-search-view .date-range-errors-container div:first-child {
  padding-right: 19.5px;
}

.con-ser-complaint-search-view .date-range-errors-container div:last-child {
  padding-left: 19.5px;
}
