/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.official-top-header-view {
  background: #cacaca;
  position: relative;
  font-weight: 400;
  padding: 4.5px 9px;
  max-width: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 36px;
}

.official-top-header-view a {
  color: #000;
  display: inline-block;
  line-height: 27px;
}

.official-top-header-view a:hover {
  color: #000;
  text-decoration: underline;
}

.official-top-header-view .lang-logo {
  width: auto;
  height: 25px;
  margin-left: 5px;
  /* margin-right: 5px; */
  margin-bottom: 0;
  margin-top: -2px;
}

.official-top-header-view a:hover {
  color: #000;
  text-decoration: underline;
}

.official-top-header-view .language-bar-left {
  margin-right: auto;
  max-width: 60%;
}

.official-top-header-view .language-bar-left a {
  padding-left: 7.2px;
}

.official-top-header-view #google_translate_element {
  position: absolute;
  display: inline-block;
  opacity: 0;
  right: 0;
  top: 0.5em;
  height: 2em;
}
