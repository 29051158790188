/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.spinner-view {
  position: relative;
  height: 50px;
}

@keyframes spinner-line-fade-more {
  0%,
  100% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
}

@keyframes spinner-line-fade-quick {
  0%,
  39%,
  100% {
    opacity: 0.25;
  }
  40% {
    opacity: 1;
  }
}

@keyframes spinner-line-fade-default {
  0%,
  100% {
    opacity: 0.22;
  }
  1% {
    opacity: 1;
  }
}
