/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.app-card-view {
  display: block;
  margin-bottom: 20px;
  background-color: #fff;
  width: 310px;
  float: left;
  margin-right: 20px;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.app-card-view img {
  width: 100%;
  height: 150px;
  display: block;
  overflow: hidden;
}

.app-card-view .card-content {
  padding: 15px;
  height: 218px;
}

.app-card-view .card-footer {
  padding: 10px 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.app-card-view .card-title {
  margin-top: 0px;
  font-weight: 500;
  font-size: 26px;
}

.app-card-view .application-description {
  font-size: 14px;
}
