/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.nondep-entity-section-identification-view .table > thead > tr > th {
  padding: 0px 8px 8px 8px;
}

.nondep-entity-section-identification-view .backgrid.table > thead > tr > td,
.nondep-entity-section-identification-view .backgrid.table > tbody > tr > td {
  padding: 2px 5px;
}

.nondep-entity-section-identification-view .backgrid.table > tbody > tr > td.file-uploader-cell {
  padding-top: 5px;
}
