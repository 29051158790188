/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2018] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.corp-fin-ext-generic-submission-view .filing-part-tab {
  min-width: 213px;
}

.corp-fin-ext-generic-submission-view .filing-part-tab .fa {
  font-size: 44px;
  padding-right: 12px;
  float: left;
}

.corp-fin-ext-generic-submission-view .filing-part-tab .tab-text {
  display: inline-block;
}

.corp-fin-ext-generic-submission-view .filing-part-tab .tab-title {
  font-size: 19px;
}

.corp-fin-ext-generic-submission-view .nav-tabs > li.active > a,
.corp-fin-ext-generic-submission-view .nav-tabs > li.active > .payment-tab {
  color: #fff;
  cursor: default;
  background-color: #002d63;
  border: 1px solid #002d63;
  border-bottom-color: transparent;
}

.corp-fin-ext-generic-submission-view .nav-tabs > li.disabled {
  background-color: #eeeeee;
  border-color: #eeeeee #eeeeee #ddd;
  color: #8a8a8a;
}

.corp-fin-ext-generic-submission-view .nav-tabs > li > .payment-tab {
  position: relative;
  display: block;
  margin-right: 2px;
  line-height: 1.42857143;
  border-radius: 4px 4px 0 0;
  padding: 10px 15px;
}
