/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.big-tabs-link-collection-view {
  margin-bottom: 20px;
}

.big-tabs-link-collection-view .big-tabs-link-view [role='tab'] {
  min-width: 300px;
}

.big-tabs-link-collection-view .big-tabs-link-view [role='tab'] .fa {
  font-size: 27px;
  padding-right: 8px;
  float: left;
  margin-top: 4px;
  margin-right: 5px;
}

.big-tabs-link-collection-view .big-tabs-link-view [role='tab'] .tab-title {
  font-size: 19px;
  line-height: 19px;
}

.big-tabs-link-collection-view .big-tabs-link-view [role='tab'] .tab-subtitle {
  font-size: 12px;
  margin-top: 2px;
}

.big-tabs-link-collection-view.nav-tabs > li {
  margin-right: 8px;
  border-color: #eeeeee #eeeeee #ddd;
  background-color: #eeeeee;
  border-radius: 4px 4px 0 0;
}

.big-tabs-link-collection-view.nav-tabs > li > a:hover {
  border-color: #d9d9d9 #d9d9d9 #d9d9d9;
}

.big-tabs-link-collection-view.nav > li > a:hover,
.big-tabs-link-collection-view.nav > li > a:focus {
  text-decoration: none;
  background-color: #d9d9d9;
}

.big-tabs-link-collection-view.nav-tabs > li.active > a {
  color: #fff;
  cursor: default;
  background-color: #002d63;
  border: 1px solid #002d63;
  border-bottom-color: transparent;
}
