/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.nondep-entity-form-officer-view .form-group,
.nondep-entity-form-officer-view .associated-licenses-instructions {
  margin-bottom: 9px;
}

.nondep-entity-form-officer-view .panel-title {
  min-height: 40px;
  line-height: 40px;
  font-size: 19px;
}

.nondep-entity-form-officer-view .form-check-input {
  width: 13px;
  height: 13px;
  padding: 0;
  margin: 0;
  vertical-align: middle;
  position: relative;
  top: -1px;
}

.nondep-entity-form-officer-view .address-margin-bottom {
  margin-bottom: 9px;
}
