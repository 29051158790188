/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.select2-container--bootstrap .select2-selection--multiple:before {
  content: ' ';
  display: block;
  position: absolute;
  border-color: #999 transparent transparent transparent;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  height: 0;
  right: 12px;
  margin-left: -4px;
  margin-top: -2px;
  top: 50%;
  width: 0;
  cursor: pointer;
}

.select2-container--open .select2-selection--multiple:before {
  content: ' ';
  display: block;
  position: absolute;
  border-color: transparent transparent #999 transparent;
  border-width: 0 4px 4px 4px;
  height: 0;
  right: 12px;
  margin-left: -4px;
  margin-top: -2px;
  top: 50%;
  width: 0;
  cursor: pointer;
}

.select2-container--bootstrap .select2-selection,
.select2-container--bootstrap .select2-selection--multiple {
  min-height: 35px;
}

.select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field {
  min-height: 35px;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice {
  margin: 6px 0 0 6px;
  font-size: 14px;
}

.select2-container--bootstrap .select2-results__option {
  padding: 4px 10px;
  font-size: 14px;
}

.select2-container--bootstrap .select2-options-bordered .select2-results__option:not(:last-child) {
  border-bottom: 1px solid #d7d7d7;
}

.select2-container--open .select2-dropdown--below {
  border-top: 1px solid #d7d7d7;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: 1px solid #d7d7d7;
}

.form-group.has-error .select2-container--bootstrap .select2-selection {
  border-color: #f04124;
}

.select2-container--bootstrap .select2-results__group {
  color: #4d4d4d;
  font-size: 17px;
}

.select2-container--bootstrap .select2-selection--single .select2-selection__arrow {
  right: 8px;
}

.select2-container--bootstrap .select2-selection--single .select2-selection__arrow b {
  border-color: #000 transparent transparent transparent;
}

.select2-container--bootstrap.select2-container--open .select2-selection .select2-selection__arrow b {
  border-color: transparent transparent #000 transparent;
}

.select2-container--bootstrap .select2-selection__clear {
  font-size: 23px;
  line-height: 21px;
  color: #f04124;
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__clear {
  margin-right: 25px;
}

.form-group.has-error .select2-container {
  margin-bottom: 20px;
}

.select2-hidden-accessible + .help-block {
  position: relative;
  top: 40px;
  height: 0;
  margin: 0;
}

.select2-search__field {
  width: 90% !important;
}
