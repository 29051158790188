/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.sec-organization-search-modal-view .table {
  margin-bottom: 0px;
}

.sec-organization-search-modal-view .pagination {
  margin-top: 5px;
  margin-bottom: 0;
}

.sec-organization-search-modal-view .list-cell li:not(:last-child) {
  margin-bottom: 5px;
}

.sec-organization-search-modal-view .backgrid.table > thead > tr > td,
.sec-organization-search-modal-view .backgrid.table > tbody > tr > td {
  padding: 4px 5px;
}

.sec-organization-search-modal-view .backgrid.table > thead > tr,
.sec-organization-search-modal-view .backgrid.table > tbody > tr {
  height: 37px;
}

.sec-organization-search-modal-view .backgrid.table tbody tr {
  font-size: 12px;
}

.sec-organization-search-modal-view .search-summary {
  width: 720px;
  display: inline-block;
  vertical-align: middle;
}
