/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,\
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.time-trk-non-dep-entry-row-view.panel.panel-default {
  margin-bottom: 0;
  border-radius: 0;
  border-bottom: 1px #ddd solid !important;
  border-left: transparent;
  border-right: transparent;
  border-top: transparent;
  margin-left: 0;
  margin-right: 0;
}

.time-trk-non-dep-entry-row-view button.remove-entry {
  margin-top: 7px;
}
