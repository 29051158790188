/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.side-nav-section-view .side-nav-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.side-nav-section-view .links-container,
.side-nav-section-view .section-container {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (max-width: 768px) {
  .side-nav-section-view .links-container {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .side-nav-section-view .links-container {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .side-nav-section-view .section-container {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 75%;
  }
}

@media (min-width: 1300px) {
  .side-nav-section-view .links-container {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .side-nav-section-view .section-container {
    max-width: 80%;
  }
}

@media (min-width: 1600px) {
  .side-nav-section-view .section-container.section-width-medium {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 60%;
  }
}

.side-nav-section-view .contents-container {
  margin-bottom: 40px;
}

.side-nav-section-view .navigation-container {
  height: 60px;
  border-top: 1px solid #eee;
  padding-top: 10px;
  margin-bottom: 20px;
}

.side-nav-section-view.side-nav-section-view-expanded .side-nav-content-view {
  margin-bottom: 40px;
}
