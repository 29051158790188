/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.non-dep-internal-individual-view .control-label.jnet-section {
  padding: 5px 20px 0 20px;
}

.non-dep-internal-individual-view .form-control-static.jnet-section {
  padding: 5px 20px 15px 20px;
}

.non-dep-internal-individual-view .control-label {
  padding-top: 5px;
  margin-bottom: 0;
}

.non-dep-internal-individual-view .form-control-static {
  padding: 5px 0 15px 0;
}
