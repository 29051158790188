/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.non-dep-license-surrender-form-view .file-upload-instruction,
.non-dep-license-surrender-form-view.checkbox-list {
  padding-top: 9px;
}

.non-dep-license-surrender-form-view .btn-container {
  margin-top: 10px;
}

.non-dep-license-surrender-form-view .required .checkbox:before {
  content: none;
}
