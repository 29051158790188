/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */
.sec-internal-dashboard-view {
  display: flex;
  align-items: stretch;
  min-height: 100%;
}

.sec-internal-dashboard-view > .side-bar-container {
  width: 200px;
  min-width: 200px;
}

@media screen and (min-width: 1300px) {
  .sec-internal-dashboard-view > .side-bar-container {
    width: 300px;
    min-width: 300px;
  }
}

.sec-internal-dashboard-view > .dashboard-container {
  padding-left: 15px;
}

.sec-internal-dashboard-view > .dashboard-container > h2 {
  margin-top: 0px;
}

.sec-internal-dashboard-view .user-tasks-container {
  margin-bottom: 40px;
}

.sec-internal-dashboard-view .button-cell > .btn {
  float: right;
}

.sec-internal-dashboard-view .table > thead > tr > th,
.sec-internal-dashboard-view .table > tbody > tr > td {
  padding: 7px 4px;
}
