/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.form-control {
  height: 35px;
  padding: 4px 10px;
}

.input-group-btn > .btn {
  min-height: 35px;
  height: 35px;
  line-height: 18px;
}

.required .control-label:after,
.required .checkbox:before {
  content: ' *';
  color: red;
}

.required-field-disclaimer-text:after {
  content: 'Required fields are denoted by an asterisk';
}

.help-block {
  margin-bottom: 0px;
}

select option[value=''] {
  color: #aaaaaa;
}

.form-static .form-group {
  margin-bottom: 0;
}

.form-group.disabled label {
  color: #aaaaaa;
}

p.form-control-static {
  white-space: pre-wrap;
}

.form-group label.long-question {
  font-weight: normal;
  margin-top: 10px;
}

.file-view {
  border-radius: 3px;
}

.form-horizontal .sub-form-vertical .form-group {
  margin-right: 0;
  margin-left: 0;
}

.form-control[readonly] {
  background-color: initial;
  opacity: initial;
}
