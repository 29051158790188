/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.header-bar-view {
  padding: 8px 0 3px 15px;
  margin: -10px -15px 10px -15px;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #e0e0e0;
}

.header-bar-view > div {
  float: left;
  min-height: 1px;
}

.header-bar-title-container {
  margin-right: 15px;
  border-right: 1px solid #c3c3c3;
}

.header-bar-view .header-bar-title {
  margin: 10px 15px 10px 0;
}
