/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.page-title {
  background-color: #ebebeb;
  padding: 15px 0 15px 15px;
  margin: -20px -15px 10px -15px;
  font-weight: 300;
}

.page-title > *,
.page-subtitle > * {
  display: inline-block;
}

.page-title .instructions-link {
  padding-right: 15px;
  font-size: 16px;
  line-height: 16px;
}

.page-title .labels-container {
  margin-left: 10px;
}

.page-title .labels-container > * {
  display: inline-block;
}

.page-title .labels-container .label {
  margin-left: 5px;
}

.page-title-buttons-container {
  float: right;
  padding-right: 15px;
}

.page-title-buttons-container > * {
  display: inline-block;
}

.page-subtitle {
  padding: 8px 0 3px 15px;
  margin: -10px -15px 10px -15px;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #e0e0e0;
}

.page-subtitle h2 {
  font-size: 32px;
  margin: 0px 10px 5px 0;
  padding: 5px 15px 5px 0;
  border-right: 1px solid #c3c3c3;
}

.page-subtitle .page-subtitle-details {
  position: relative;
  top: -2px;
  font-size: 22px;
  font-weight: 300;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, sans-serif;
}

.dashboard-title h2 {
  margin: 0 0 15px 0;
}

.dashboard-title > * {
  display: inline-block;
}
