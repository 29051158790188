/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

.input-label {
  margin: 5px 0 0 0;
  font-style: italic;
  color: #737373;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.normal-break {
  word-break: normal;
  overflow: auto;
  white-space: normal;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrap-break {
  word-break: break-all;
  overflow: auto;
  white-space: normal;
}

.font-weight-normal {
  font-weight: normal;
}

.text-break {
  word-wrap: break-word;
}

.pre-wrap {
  white-space: pre-wrap;
}

.list-unstyled {
  margin-bottom: 0;
}

.red-text {
  color: #b94a48;
}
