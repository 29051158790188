/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,\
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.time-trk-totals-view .form-group {
  margin-top: 10px !important;
  margin-bottom: 10px;
}

.time-trk-totals-view .col-hr {
  width: 10%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.time-trk-totals-view .panel.panel-default {
  margin-bottom: 0;
  border-radius: 0;
  border-bottom: 1px #ddd solid !important;
  border-left: transparent;
  border-right: transparent;
  border-top: transparent;
}

.time-trk-totals-view .panel-body {
  padding: 5px 15px 5px 15px;
}

.time-trk-totals-view .error {
  color: #b94a48;
  font-weight: bold;
}
.time-trk-totals-view .error-format {
  float: left;
  position: relative;
  min-height: 1px;
  padding-left: 10px;
  color: #b94a48;
  font-weight: bold;
}
