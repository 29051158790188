/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.license-contacts-section-view .add-contacts-container {
  display: table;
  width: 100%;
  margin-bottom: 15px;
}

.license-contacts-section-view .add-existing-contact-cell {
  display: table-cell;
  width: 100%;
}

.license-contacts-section-view .new-contact-cell {
  display: table-cell;
}

.license-contacts-section-view .btn-add-contact {
  height: 35px;
  min-height: 35px;
  line-height: 18px;
  margin-left: 10px;
  margin-top: -2px;
}

.license-contacts-section-view select.existing-contact-select {
  width: 100%;
  float: none;
}
