/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,\
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.dep-subfund-activities-item-view.panel.panel-default {
  border-radius: 0;
  border-bottom: 1px #ddd solid !important;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}

.dep-subfund-activities-item-view.panel.panel-default:nth-child(2n) {
  background-color: #f9f9f9;
}

.dep-subfund-activities-item-view.panel.panel-default .panel-body {
  padding: 0px 15px 0px 15px;
}

.dep-subfund-activities-item-view.panel.panel-default .form-control-static {
  padding: 0;
}

.dep-subfund-activities-item-view.panel.panel-default .row.location-info .form-group {
  margin-top: 5px;
  margin-bottom: 5px;
}
