/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.bs-callout {
  margin: 5px 0 20px 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #eee;
}

.bs-callout h4 {
  margin-top: 0;
}

.bs-callout p:last-child {
  margin-bottom: 0;
}

.bs-callout code,
.bs-callout .highlight {
  background-color: #fff;
}

.bs-callout-muted {
  background-color: #f0f0f0;
  border-color: #aaa;
}

.bs-callout-muted h4 {
  color: #aaa;
}

.bs-callout-primary {
  background-color: #f6f6f6;
  border-color: #337ab7;
}

.bs-callout-primary h4 {
  color: #337ab7;
}

.bs-callout-success {
  background-color: #dff0d8;
  border-color: darkgreen;
}

.bs-callout-success h4 {
  color: darkgreen;
}

.bs-callout-info {
  background-color: #f0f7fd;
  border-color: #1b809e;
}

.bs-callout-info h4 {
  color: #3a87ad;
}

.bs-callout-warning {
  background-color: #fefbed;
  border-color: #aa6708;
}

.bs-callout-warning h4 {
  color: #aa6708;
}

.bs-callout-danger {
  background-color: #fcf2f2;
  border-color: darkred;
}

.bs-callout-danger h4 {
  color: darkred;
}
