/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.navbar {
  border-radius: 0;
  font-size: 14px;
  font-weight: 400;
}

.navbar .border {
  display: inline-block;
  height: 56px;
  width: 40px;
}

.navbar-header .logo-text {
  cursor: pointer;
  display: inline-block;
  margin-right: 3px;
}

.navbar-header .logo {
  height: 60px;
  width: auto;
  float: left;
  padding-right: 3px;
  padding: 9px 0;
}

.navbar-brand {
  font-weight: 400;
  padding: 0;
  height: 56px;
}

.navbar-brand,
.navbar-nav > li > a {
  line-height: 46px;
}

.navbar.navbar-ext-default {
  background-image: url(9ac9bbe8168acf6489ae77be03a5205c.jpg);
  background-color: #cccccc;
  height: 68px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border: 0;
}

.navbar.navbar-ext-default .navbar .nav > li > a,
.navbar.navbar-ext-default .navbar-nav > li > a,
.navbar.navbar-ext-default .navbar-text {
  padding: 10px;
}

.navbar.navbar-ext-default .navbar-brand {
  padding-left: 0;
}

.navbar-text {
  line-height: 47px;
  margin: 0;
  color: white;
}

.nav > li > a {
  position: relative;
  display: block;
}

.navbar-text,
.navbar .nav > li > a {
  padding: 9px;
}

.navbar-ext-default > .container-fluid > .collapse > .nav > li > a:hover,
.navbar-ext-default > .container-fluid > .collapse > .nav > li > a:focus {
  color: #ffffff;
  background-color: inherit;
}

.navbar-ext-default > .container-fluid > .collapse > .nav > li > a {
  color: #d4ebf2;
}

@media (min-width: 768px) {
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-left: 0px;
  }
}
