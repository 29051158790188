/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.search-highlight-cell > table {
  width: 100%;
  max-width: 100%;
}

.search-highlight-cell .search-highlight-cell-row {
  height: 15px;
}

.search-highlight-cell td {
  display: table-cell;
  border: 0;
  line-height: 15px;
}

.search-highlight-cell .search-highlight-cell-key {
  width: 33%;
}

.search-highlight-cell .search-highlight-cell-value em {
  font-weight: bold;
  font-style: normal;
}
