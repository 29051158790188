/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,\
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

/* Buttons and labels */
.time-trk-sec-examiner-entry-view .col-hr-label {
  width: 10%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.time-trk-sec-examiner-entry-view .labels {
  border-bottom: 1px #ddd solid;
  margin-left: 0;
  margin-right: 0;
}

.time-trk-sec-examiner-entry-view .pay-period-title {
  padding-bottom: 10px;
  border-bottom: 1px #eee solid !important;
}

.time-trk-sec-examiner-entry-view .add {
  margin-top: 20px;
}

.time-trk-sec-examiner-entry-view .btn-clear-object {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

/* Spacing Grid Columns for Time Entries Table */
.time-trk-sec-examiner-entry-view .col-time-category {
  width: 21%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.time-trk-sec-examiner-entry-view .col-time-object {
  width: 26%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.time-trk-sec-examiner-entry-view .col-time-hrs {
  width: 50%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.time-trk-sec-examiner-entry-view .col-hr {
  width: 10%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.time-trk-sec-examiner-entry-view .col-time-remove {
  width: 3%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.time-trk-sec-examiner-entry-view .col-offset-category-and-exam {
  margin-left: 47%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.time-trk-sec-examiner-entry-view .panel-body {
  padding-left: 0px;
  padding-right: 0px;
}

.time-trk-sec-examiner-entry-view .time-entries-section {
  overflow-x: auto;
}

.time-trk-sec-examiner-entry-view .time-entries-section > * {
  min-width: 1890px;
}

.time-trk-sec-examiner-entry-view .time-entries-section .row {
  margin-left: 0px;
  margin-right: 0px;
}

.time-trk-sec-examiner-entry-view .time-entries-section .row:first-child {
  padding-left: 0px;
}

.time-trk-sec-examiner-entry-view .time-entries-section .row:last-child {
  padding-left: 0px;
}

/* Spacing for Comments Section */
.time-trk-sec-examiner-entry-view .col-comments-section {
  width: 40%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

/* Other page alignments */
.time-trk-sec-examiner-entry-view .form-group {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.time-trk-sec-examiner-entry-view .form-control {
  margin-top: 0 !important;
}
