/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.con-ser-internal-side-bar-nav-view .nav-pills > li > a {
  border-radius: 0;
}

.con-ser-internal-side-bar-nav-view {
  margin-left: -15px;
  background-color: #f4f4f4;
  box-shadow: inset -1px 0 0 #e5e5e5;
  min-height: calc(100% + 20px);
  margin-top: -20px;
}

.con-ser-internal-side-bar-nav-view p {
  padding: 10px 0px 5px 15px;
  font-weight: 600;
  color: #9e9e9e;
  margin: 0px 0px;
}

.con-ser-internal-side-bar-nav-view .on-deck-icon {
  width: 26px;
  padding: 5px 0px 5px;
}

.con-ser-internal-side-bar-nav-view .on-deck-item {
  display: flex;
  align-items: center;
  padding-right: 15px;
}
.con-ser-internal-side-bar-nav-view .on-deck-label {
  flex-grow: 1;
  line-height: 24px;
}

.con-ser-internal-side-bar-nav-view .on-deck-counter {
  color: #ffffff;
  background-color: #38761d;
  border-radius: 10px;
  padding: 2.8px 5px 2.8px 5px;
  font-weight: bold;
  height: 26px;
}

.con-ser-internal-side-bar-nav-view .menu-item-icon {
  width: 30px;
  font-size: 16px;
  padding: 0px 5px 0px 5px;
}

.con-ser-internal-side-bar-nav-view .menu-item-label {
  font-size: 16px;
}
