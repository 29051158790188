/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.login-view {
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
}

.login-row {
  width: 900px;
  display: flex;
  flex-wrap: wrap;
}

.ext-login-view {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.inst-search-view {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.complaint-form-view {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.login-view .title {
  padding: 30px 0;
}

.ext-banner {
  width: 900px;
}

.login-view .logo {
  max-width: 170px;
  padding-right: 15px;
  margin-right: 20px;
  border-right: 1px solid #e6e8e8;
  display: inline-block;
}

.login-view .title-text {
  display: inline-block;
  max-width: 250px;
  height: 105px;
  vertical-align: top;
  font-size: 47px;
}

.title-logo {
  align-items: center;
  display: inline-block;
  margin-top: -5px;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-image: none;
  border: 1px solid transparent;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 0px;
  color: #fff;
  background-color: #2a578d;
  border-color: #2a578d;
}
