/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.backgrid {
  table-layout: fixed;
}

.backgrid .string-cell,
.backgrid .number-cell {
  text-align: center;
}

.backgrid th.renderable,
.backgrid td.renderable {
  vertical-align: middle;
}

table,
.table,
.backgrid {
  font-size: 14px;
}

.backgrid td {
  text-align: left !important;
  white-space: normal;
  word-wrap: break-word;
}

.backgrid td.select-row-cell {
  text-align: center !important;
}

.backgrid .empty td {
  text-align: center !important;
}

.backgrid tr {
  height: 45px;
}

.backgrid tbody .empty span {
  line-height: 27px;
}

.backgrid thead th {
  background-color: inherit;
  text-align: left;
}

.backgrid thead th button {
  white-space: normal;
  text-align: left;
}

.table > thead > tr > th {
  border-bottom: 1px solid #dddddd;
}

td .file-view {
  margin: 0 auto 0 auto;
}

.pagination {
  margin-top: 0;
}

.backgrid .static-input-cell .form-group {
  margin-bottom: 0;
}
