/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.nondep-entity-location-view .ppob-view {
  color: #3a87ad;
  background-color: #d9edf7;
  border: 1px solid;
  border-radius: 5px;
  border-color: #bce8f1;
  padding: 1px 10px 2px 5px;
  display: inline-block;
  margin: 5px 0;
}

.nondep-entity-location-view .panel-entity-location.control-label {
  text-align: right;
}

.nondep-entity-location-view .backgrid > thead > tr {
  height: auto;
}

.nondep-entity-location-view .table {
  margin-bottom: 0px;
}

.nondep-entity-location-view .table > thead > tr > th {
  padding: 0px 8px 8px 8px;
}

.nondep-entity-location-view .backgrid.table > thead > tr:not(.empty) > td,
.nondep-entity-location-view .backgrid.table > tbody > tr:not(.empty) > td {
  padding: 2px 5px;
}

.nondep-entity-location-view .backgrid tr {
  height: 36px;
}
