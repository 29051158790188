/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

.external-footer-view {
  position: relative;
  background: #003146;
}

.external-footer-view .copyright {
  padding: 8px 20px;
  color: #fafafa;
  width: 100%;
  min-width: 100%;
  height: 36px;
}

.external-footer-view .copyright ul,
.external-footer-view .copyright p {
  margin-bottom: 0;
}

.external-footer-view .copyright a,
.external-footer-view .copyright p {
  font-size: 12px;
  letter-spacing: 0.1em;
  font-weight: 400;
  margin: 0;
}

.external-footer-view .copyright li {
  list-style: none;
}

.external-footer-view .copyright li a {
  color: #fff;
  border-right: solid 1px #666;
  padding: 3px 20px;
  float: left;
  text-transform: uppercase;
}
