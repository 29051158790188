/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

html {
  position: relative;
  min-height: 100%;
}

body {
  background: #fbfbfb;
}

html,
body {
  height: 100%;
  width: 100%;
  font-weight: 400;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, sans-serif;
}

body.print-friendly {
  background: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 300;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, sans-serif;
}

/* subtract off height from body equal to the height of the header content */
body.internal #__content {
  height: calc(100% - 88px);
}

body.external #__content {
  /* height: calc(100% - 124px);   */
  min-height: calc(100% - 160px);
}
