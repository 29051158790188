/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

/* Correct AddresView horizontal values to match rest of form */
@media (min-width: 768px) {
  .conser-complaint-section-contact-view .address-view .col-sm-3.control-label {
    width: 16.66666667%;
  }

  .conser-complaint-section-contact-view .address-view .col-sm-offset-3 {
    margin-left: 16.66666667%;
  }

  .conser-complaint-section-contact-view .address-view .postal-code-form-group .col-sm-6.control-label {
    width: 33.33333333%;
  }

  .conser-complaint-section-contact-view .address-view .state-form-group .col-sm-5.control-label {
    width: 33.33333333%;
  }

  .conser-complaint-section-contact-view .address-view .state-form-group .col-sm-7 {
    width: 50%;
  }
}

.conser-complaint-section-contact-view .contact-select-container {
  display: inline-block;
  width: 50%;
}

.conser-complaint-section-contact-view .contact-select-container .spinner-view {
  position: inherit;
  height: 0;
}

.conser-complaint-section-contact-view .contact-select-container .spinner {
  left: 5% !important;
  top: 47% !important;
}
